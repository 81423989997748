<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Pago de Crédito - Nuevo</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/pago-credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Crédito:</label>
                    <v-select @input="GetCreditPayment" placeholder="Seleccione un credito" class="w-100" :filterable="false" label="text" v-model="credit" @search="SearchCredits" :options="credits"></v-select>
                    <small v-if="errors.id_credit" class="form-text text-danger" >Seleccione un crédito</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Credito:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_payment.credit_code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Programa:">
                    <b-form-input readonly class="text-left" type="text" v-model="credit_payment.credit_program"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo Programa:">
                    <b-form-input readonly class="text-left" type="text" v-model="credit_payment.credit_program_type"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input readonly class="text-right" type="text" v-model="credit_payment.credit_amount"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Garante:">
                    <b-form-input readonly class="text-left" type="text" v-model="credit_payment.credit_guarantor"></b-form-input>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Nro Pago:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_payment.code"></b-form-input>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="credit_payment.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input type="date" disabled class="text-center" v-model="credit_payment.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="9">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_payment.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Anexos:">
                     <b-form-file accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                
                          <th width="5%" class="text-center">Cuota</th>
                          <th width="7%" class="text-center">Fecha</th>
                      
                          <th width="6%" class="text-center">Capital</th>
                          <th width="6%" class="text-center">Interes</th>
                          
                          <th width="6%" class="text-center">Cuota</th>
                          <th width="6%" class="text-center">Amort.</th>
                          <th width="6%" class="text-center">P. Capital</th>
                          <th width="6%" class="text-center">P. Interés</th>
                          <th width="6%" class="text-center">Dias Moros.</th>
                          <th width="6%" class="text-center">Morosidad</th>
                          <th width="6%" class="text-center">P. Mora</th>
                          <th width="8%" class="text-center">Desc. <br> Interes</th>
                          <th width="8%" class="text-center">Desc. <br> Morosidad</th>

                          <th width="6%" class="text-center">Cuota <br> Final</th>
                          <th width="6%" class="text-center">Saldo <br> Capital</th>
                          <th width="3%" class="text-center"> Monto <br>A Pagar </th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credits_details" :key="it">
                        <tr>
                 
                          <td :class="'text-center '+ (item.credit_plus == 1 ? ' bg-primary text-white':'')">{{ item.quota_number }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right bg-warning"> {{ item.quota  }}</td>
                          <td class="text-right bg-info"> {{ item.installment_amortization  }}</td>
                          <td class="text-right"> {{ item.payment_capital  }}</td>
                          <td class="text-right"> {{ item.payment_interes  }}</td>
                          <td class="text-right"> {{ item.days_delinquency  }}</td>
                          <td class="text-right"> {{ item.delinquency  }}</td>
                          <td class="text-right"> {{ item.payment_delinquency  }}</td>
                          <td class="text-right">
                            <b-form-input :disabled="parseFloat(item.installment_amortization) >= parseFloat(item.interest)" type="number" @change="UpdatedCreditPayment(it)" class="text-right" v-model="item.discount_interest" step="any"></b-form-input>
                          </td>
                          <td class="text-right">
                            <b-form-input type="number" @change="UpdatedCreditPayment(it)" class="text-right" v-model="item.discount_delinquency" step="any"></b-form-input>
                          </td>
                          <td class="text-right bg-success"> {{ item.total_payment  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                          <td class="text-center">
                            <b-form-checkbox @change="TotalCreditPayment" v-model="item.select" value="1" unchecked-value="0"></b-form-checkbox>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="bg-info">
                          <td colspan="2"></td>
                          <td class="text-right">{{ total.capital_balance }}</td>
                          <td class="text-right">{{ total.interest }}</td>
                          <td class="text-right"></td>
                          <td class="text-right">{{ total.installment_amortization }}</td>
                          <td class="text-right">{{ total.payment_capital }}</td>
                          <td class="text-right">{{ total.payment_interes }}</td>
                          <td class="text-right"></td>
                          <td class="text-right">{{ total.delinquency }}</td>
                          <td class="text-right">{{ total.payment_delinquency }}</td>
                          <td class="text-right">{{ total.discount_interest }}</td>
                          <td class="text-right">{{ total.discount_delinquency }}</td>
                          <td class="text-right"></td>
                          <td class="text-right"></td>
                          <td class="text-right"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </b-col>

                <b-col md="9">
                </b-col>
                <b-col md="3">
                  <div class="table-responsive">
                    <table  class="table table-hover table-lg mt-lg mb-0">
                      <tbody>
                        <tr>
                            <td class="align-middle text-right text-total">Subtotal:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.subtotal"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Morosidad:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.delinquency"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Descuento:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.discount"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Total:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="credit_payment.total"></b-form-input>
                            </td>
                        </tr>
                        <tr v-if="errors.total">
                          <td colspan="2" class="text-center">
                            <small  class="form-text text-danger" >Seleccione 1 o mas cuotas a pagar</small>    
                          </td>
                        </tr>

                        
                      </tbody>
                    </table>
                  </div>
                </b-col>


               

                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="ShowModalInstallmentAmortization" class="form-control" variant="warning" >Amortizar Cuota</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalInstallmentAmortization />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";
// components
import ModalClients from './../components/ModalClient'
import ModalInstallmentAmortization from './components/ModalInstallmentAmortization'
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentAdd",
  components:{
      vSelect,
      ModalClients,
      ModalInstallmentAmortization,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CreditPayment',
      role: 2,
      credit_payment: {
          id_credit_payment:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_credit:'',
          credit_code:'',
          credit_program:'',
          credit_program_type:'',
          credit_amount:'',
          credit_guarantor:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          observation:'',
          file:'',
          subtotal:'0.00',
          delinquency:'0.00',
          discount:'0.00',
          total:'0.00',
          state:1,
          created_at:'',
          updated_at:'',
      },
      total: {
        capital_balance: '0.00',
        interest: '0.00',
        installment_amortization: '0.00',
        delinquency: '0.00',
        discount_interest: '0.00',
        discount_delinquency: '0.00',

        payment_capital: '0.00',
        payment_interes: '0.00',
        payment_delinquency: '0.00',
      },
      file:null,
      modules:[],
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      credit: null,
      credits: [],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      credits_details:[],
      errors: {
        id_client: false,
        payment_method: false,
        date: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    EventBus.$on('ResetPaymentCredit', () => {
      this.credit = null;
      this.GetCreditPayment();
    });
    this.GetCorrelative();
  },
  methods: {
    GetCorrelative,
    GetCreditPayment,

    AddCreditPayment,
    Validate,

    SearchCredits,
    ShowModalClient,
    onFileChange,

    UpdatedCreditPayment,
    TotalCreditPayment,
    TotalCreditDetail,
    OpenVoucher,
    ShowModalInstallmentAmortization,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function ShowModalInstallmentAmortization() {
  EventBus.$emit('ModalInstallmentAmortizationShow');
}

function GetCorrelative() {
  let me = this;
  let url = me.url_base + "data/get-correlative/CreditPayment";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_payment.code = response.data.result.number;
      }
    });
}

function SearchCredits(search, loading) {
  
  let me = this;
   let url = this.url_base + "credit/clients";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.credits = response.data.result;
           loading(false);
     })
   }
}

function ShowModalClient() {
  EventBus.$emit('ModalClientsShow');
}

function AddCreditPayment() {
  let me = this;
  let url = me.url_base + "credit-payment/add";
  me.isLoading = true;
  let data = new FormData();
  data.append("id_user", this.user.id_user);
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_credit", this.credit.value);
  data.append("code", this.credit_payment.code);
  data.append("payment_method", this.credit_payment.payment_method);
  data.append("observation", this.credit_payment.observation);
  data.append("date", this.credit_payment.date);
  data.append("file", this.credit_payment.file);
  data.append("subtotal", this.credit_payment.subtotal);
  data.append("delinquency", this.credit_payment.delinquency);
  data.append("discount", this.credit_payment.discount);
  data.append("total", this.credit_payment.total);
  data.append("state", this.credit_payment.state);
  data.append("credits_details", JSON.stringify(this.credits_details));
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.OpenVoucher(response.data.result.id_credit_payment);
        me.$router.push({ name: "CreditPaymentList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_credit = this.credit == null ? true : false;
  this.errors.payment_method = this.credit_payment.payment_method.length == 0 ? true : false;
  this.errors.date = this.credit_payment.date.length == 0 ? true : false;
  this.errors.code = this.credit_payment.code.length == 0 ? true : false;
  this.errors.total = this.credit_payment.total.length == 0 || parseFloat(this.credit_payment.total) <= 0  ? true : false;

  if (this.errors.id_credit) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el pago de crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCreditPayment();
    }
  });
}

function onFileChange(e) {
  this.credit_payment.file = e.target.files[0];
}

function GetCreditPayment() {
  let me = this;
  console.log("res")
  let url = me.url_base + "credit/search-credit-payment";
  if (this.credit == null) {
    me.credits_details = [];
    me.credit_payment.credit_code = '';
    me.credit_payment.credit_program = '';
    me.credit_payment.credit_program_type = '';
    me.credit_payment.credit_amount = '';
    me.credit_payment.credit_guarantor = '';


    me.total.capital_balance = '0.00';
    me.total.interest = '0.00';
    me.total.installment_amortization = '0.00';
    me.total.delinquency = '0.00';
    me.total.discount_interest = '0.00';
    me.total.discount_delinquency = '0.00';

    me.total.payment_capital = '0.00';
    me.total.payment_interes = '0.00';
    me.total.payment_delinquency = '0.00';

    return false;
  }
  let data = {
    id_credit : this.credit.value
  }
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.credits_details = response.data.result.credit_details;

      me.credit_payment.credit_code = response.data.result.credit.code;
      me.credit_payment.credit_program = response.data.result.credit.product_name;
      me.credit_payment.credit_program_type = response.data.result.credit.product_type_name;
      me.credit_payment.credit_amount = response.data.result.credit.disbursement_capital;
      me.credit_payment.credit_guarantor = response.data.result.credit.guarantor;


      me.total.payment_capital = response.data.result.total.payment_capital;
      me.total.payment_interes = response.data.result.total.payment_interes;
      me.total.payment_delinquency = response.data.result.total.payment_delinquency;
      me.total.capital_balance = response.data.result.total.capital_balance;
      me.total.interest = response.data.result.total.interest;
      me.total.installment_amortization = response.data.result.total.installment_amortization;
      me.total.delinquency = response.data.result.total.delinquency;

    
    }
  });
}

function UpdatedCreditPayment(index) {

  this.credits_details[index].quota = this.credits_details[index].quota.length == 0 ? 0 : this.credits_details[index].quota;
  this.credits_details[index].delinquency = this.credits_details[index].delinquency.length == 0 ? 0 : this.credits_details[index].delinquency;

  this.credits_details[index].discount_capital = this.credits_details[index].discount_capital.length == 0 ? 0 : this.credits_details[index].discount_capital;
  this.credits_details[index].discount_interest = this.credits_details[index].discount_interest.length == 0 ? 0 : this.credits_details[index].discount_interest;
  this.credits_details[index].discount_delinquency = this.credits_details[index].discount_delinquency.length == 0 ? 0 : this.credits_details[index].discount_delinquency;


  
  this.credits_details[index].total_payment = parseFloat(this.credits_details[index].quota) - parseFloat(this.credits_details[index].installment_amortization) + parseFloat(this.credits_details[index].delinquency) - parseFloat(this.credits_details[index].discount_capital) - parseFloat(this.credits_details[index].discount_interest) - parseFloat(this.credits_details[index].discount_delinquency);

  this.credits_details[index].discount_capital = parseFloat(this.credits_details[index].discount_capital).toFixed(2);
  this.credits_details[index].discount_interest = parseFloat(this.credits_details[index].discount_interest).toFixed(2);
  this.credits_details[index].discount_delinquency = parseFloat(this.credits_details[index].discount_delinquency).toFixed(2);
  this.credits_details[index].total_payment = parseFloat(this.credits_details[index].total_payment).toFixed(2);

  this.TotalCreditPayment();
  this.TotalCreditDetail();
}

function TotalCreditDetail() {
  let total = {
    discount_interest:0,
    discount_delinquency:0,
  }
  this.credits_details.forEach(element => {
      total.discount_interest += parseFloat(element.discount_interest);
      total.discount_delinquency += parseFloat(element.discount_delinquency);
  }); 

  this.total.discount_interest = parseFloat(total.discount_interest).toFixed(2);
  this.total.discount_delinquency = parseFloat(total.discount_delinquency).toFixed(2);
}

function TotalCreditPayment() {
  let total = {
    subtotal:0,
    delinquency:0,
    discount:0,
    total:0,
  }
  this.credits_details.forEach(element => {
    if (element.select == "1") {
      total.subtotal += parseFloat(element.quota);
      total.delinquency += parseFloat(element.delinquency);
      total.discount += parseFloat(element.discount_capital) + parseFloat(element.discount_interest) + parseFloat(element.discount_delinquency);
      total.total += parseFloat(element.total_payment);
    }
  }); 

  this.credit_payment.subtotal = parseFloat(total.subtotal).toFixed(2);
  this.credit_payment.delinquency = parseFloat(total.delinquency).toFixed(2);
  this.credit_payment.discount = parseFloat(total.discount).toFixed(2);
  this.credit_payment.total = parseFloat(total.total).toFixed(2);
}

function OpenVoucher(id_credit_payment) {
  let me = this;

  let url1 = me.url_base + "credit-payment-biil-of-sale/"+ id_credit_payment;
  let url2 = me.url_base + "credit-payment-sales-of-receip/"+ id_credit_payment;
  

  let url = me.url_base + "credit-payment/get-information-voucher/"+id_credit_payment;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        if (parseFloat(response.data.result.bill_sale) > 0) {
          var ventana1 = window.open(url1, '_blank');
        }

        if (parseFloat(response.data.result.sales_receipt) > 0) {
          setTimeout(function(){
              var ventana2 = window.open(url2, '_blank');
          }, 250);
        }
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });

}

</script>
